import React, { useState, useEffect, useRef, useCallback } from 'react';
import * as pdfjsLib from 'pdfjs-dist';
import DocViewer, { DocViewerRenderers } from "@cyntler/react-doc-viewer";
import './DocumentViewer.css';

pdfjsLib.GlobalWorkerOptions.workerSrc = `${process.env.PUBLIC_URL}/pdf.worker.min.js`;
const DocumentViewer = ({ url }) => {
  const [fileType, setFileType] = useState('');
  const [numPages, setNumPages] = useState(null);
  const [pageNumber, setPageNumber] = useState(1);
  const [error, setError] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [pdfDoc, setPdfDoc] = useState(null);
  const [scale, setScale] = useState(1);
  const canvasRef = useRef(null);
  const containerRef = useRef(null);

  const detectFileType = useCallback((url) => {
    const extension = url.split('.').pop().toLowerCase();
    if (['pdf'].includes(extension)) return 'pdf';
    if (['doc', 'docx'].includes(extension)) return 'word';
    if (['ppt', 'pptx'].includes(extension)) return 'powerpoint';
    if (['xls', 'xlsx'].includes(extension)) return 'excel';
    return 'unknown';
  }, []);

  useEffect(() => {
    const fileType = detectFileType(url);
    setFileType(fileType);
    setError(null);
    setIsLoading(true);
    setPageNumber(1);
    setScale(1);

    if (fileType === 'pdf') {
      loadPDF(url);
    } else if (['word', 'powerpoint', 'excel'].includes(fileType)) {
      loadOfficeDocument(url);
    } else {
      setIsLoading(false);
      setError('不支持的文件类型');
    }
  }, [url, detectFileType]);

  const loadPDF = async (url) => {
    const maxRetries = 3;
    let retries = 0;

    while (retries < maxRetries) {
      try {
        const loadingTask = pdfjsLib.getDocument(url);
        const pdf = await loadingTask.promise;
        setPdfDoc(pdf);
        setNumPages(pdf.numPages);
        setIsLoading(false);
        renderPage(1, pdf);
        return;
      } catch (err) {
        console.error(`Error loading PDF (attempt ${retries + 1}):`, err);
        retries++;
        if (retries === maxRetries) {
          setError(`加载 PDF 时出错: ${err.message}`);
          setIsLoading(false);
        }
        await new Promise(resolve => setTimeout(resolve, 1000));
      }
    }
  };

  const renderPage = useCallback(async (num, doc = pdfDoc) => {
    if (!doc || !canvasRef.current) return;

    try {
      const page = await doc.getPage(num);
      const viewport = page.getViewport({ scale });

      const canvas = canvasRef.current;
      const context = canvas.getContext('2d');
      canvas.height = viewport.height;
      canvas.width = viewport.width;

      const renderContext = {
        canvasContext: context,
        viewport: viewport
      };

      await page.render(renderContext).promise;
    } catch (err) {
      console.error('Error rendering page:', err);
      setError(`渲染页面时出错: ${err.message}`);
    }
  }, [pdfDoc, scale]);

  useEffect(() => {
    if (pdfDoc) {
      renderPage(pageNumber);
    }
  }, [pageNumber, pdfDoc, renderPage, scale]);

  const changePage = useCallback((offset) => {
    setPageNumber((prevPageNumber) => {
      const newPageNumber = prevPageNumber + offset;
      return Math.max(1, Math.min(newPageNumber, numPages));
    });
  }, [numPages]);

  const previousPage = useCallback(() => changePage(-1), [changePage]);
  const nextPage = useCallback(() => changePage(1), [changePage]);

  const handleZoom = useCallback((delta) => {
    setScale((prevScale) => {
      const newScale = prevScale + delta;
      return Math.max(0.5, Math.min(newScale, 3));
    });
  }, []);

  const handleWheel = useCallback((e) => {
    if (e.ctrlKey) {
      e.preventDefault();
      handleZoom(e.deltaY > 0 ? -0.1 : 0.1);
    }
  }, [handleZoom]);

  const handleTouchStart = useCallback((e) => {
    if (e.touches.length === 2) {
      const touch1 = e.touches[0];
      const touch2 = e.touches[1];
      const distance = Math.hypot(touch1.clientX - touch2.clientX, touch1.clientY - touch2.clientY);
      containerRef.current.dataset.initialPinchDistance = distance;
    }
  }, []);

  const handleTouchMove = useCallback((e) => {
    if (e.touches.length === 2) {
      const touch1 = e.touches[0];
      const touch2 = e.touches[1];
      const distance = Math.hypot(touch1.clientX - touch2.clientX, touch1.clientY - touch2.clientY);
      const initialDistance = parseFloat(containerRef.current.dataset.initialPinchDistance);
      const scaleDelta = (distance - initialDistance) / 100;
      handleZoom(scaleDelta);
      containerRef.current.dataset.initialPinchDistance = distance;
    }
  }, [handleZoom]);

  const handleOfficeError = (error) => {
    console.error('Error loading Office document:', error);
    setError(`加载文档时出错: ${error.message || '未知错误'}`);
    setIsLoading(false);
  };

  const loadOfficeDocument = async (url) => {
    try {
      const response = await fetch(url, { method: 'HEAD' });
      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }
      setIsLoading(false);
    } catch (error) {
      handleOfficeError(error);
    }
  };

  const renderPDF = () => (
    <div 
      className="pdf-container" 
      ref={containerRef}
      onWheel={handleWheel}
      onTouchStart={handleTouchStart}
      onTouchMove={handleTouchMove}
    >
      {error ? (
        <div className="error-message">{error}</div>
      ) : (
        <>
          {isLoading ? (
            <div className="loading-message">加载中...</div>
          ) : (
            <>
              <div className="canvas-container" style={{ transform: `scale(${scale})` }}>
                <canvas ref={canvasRef} />
              </div>
              <div className="pdf-controls">
                <button onClick={previousPage} disabled={pageNumber <= 1}>上一页</button>
                <span className="page-info">第 {pageNumber} 页，共 {numPages} 页</span>
                <button onClick={nextPage} disabled={pageNumber >= numPages}>下一页</button>
                <button onClick={() => handleZoom(0.1)}>放大</button>
                <button onClick={() => handleZoom(-0.1)}>缩小</button>
              </div>
            </>
          )}
        </>
      )}
    </div>
  );

  const renderOffice = () => (
    <div className="office-container">
      {error ? (
        <div className="error-message">
          {error}
          <p>可能的原因：</p>
          <ul>
            <li>文档 URL 不正确或文档不存在</li>
            <li>服务器不允许跨域请求 (CORS 问题)</li>
            <li>网络连接问题</li>
          </ul>
          <p>建议：</p>
          <ul>
            <li>检查文档 URL 是否正确</li>
            <li>确保服务器允许跨域请求</li>
            <li>检查网络连接</li>
          </ul>
        </div>
      ) : isLoading ? (
        <div className="loading-message">加载中...</div>
      ) : (
        <DocViewer
          documents={[{ uri: url }]}
          pluginRenderers={DocViewerRenderers}
          config={{
            header: {
              disableHeader: true,
              disableFileName: true,
              retainURLParams: true
            },
          }}
          style={{ height: '100vh', width: '100vw' }}
          onError={handleOfficeError}
        />
      )}
    </div>
  );

  const renderViewer = () => {
    switch (fileType) {
      case 'pdf':
        return renderPDF();
      case 'word':
      case 'powerpoint':
      case 'excel':
        return renderOffice();
      default:
        return <div>不支持的文件类型</div>;
    }
  };

  return (
    <div className="document-viewer">
      {renderViewer()}
    </div>
  );
};

export default DocumentViewer;