import React from 'react';
import { BrowserRouter as Router, useLocation } from 'react-router-dom';
import DocumentViewer from './components/DocumentViewer';

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

function ViewerComponent() {
  const query = useQuery();
  const url = query.get('url');

  if (!url) {
    return <div>请在URL中提供文档地址。例如：?url=http://example.com/document.pdf</div>;
  }

  return <DocumentViewer url={url} />;
}

function App() {
  return (
    <Router>
      <ViewerComponent />
    </Router>
  );
}

export default App;